import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
// import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
registerLocaleData(en);
import { CookieService } from 'ngx-cookie-service';
import { SEOService } from './seo.service';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'medbuzz-angular-web' }),
    AppRoutingModule,
    // FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserTransferStateModule,
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }, CookieService, SEOService],
  bootstrap: [AppComponent],
})
export class AppModule {}
