import { Component, } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AppService } from './app.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  dogs: any;
  title = 'medbuzz-angular-web';
  isRouterView = false
  constructor(
    private AppService: AppService,
    private cookieService: CookieService) {

    // this.getSplashScreen()
    if (this.cookieService.check('DeviceID')) {

      if (this.cookieService.check('ApiKey')) {
        this.isRouterView = true

      } else {
        this.getDeviceIdData()
      }
    } else {
      this.getSplashScreen()
    }


  }

  getSplashScreen() {
    this.AppService.postMethod('Generate_DeviceID', {}).subscribe((data: any) => {
      console.log('data', data)
      if (data.success) {
        this.cookieService.set('DeviceID', data.extras.Data.DeviceID)
        this.getDeviceIdData()
      }
      // this.cookieService.set('DeviceID',data.)
    })

  }
  getDeviceIdData() {
    this.AppService.postMethod('Splash_Screen', {
      "DeviceID": this.cookieService.get('DeviceID'),
      "DeviceType": 3,
      "DeviceName": "One WEB",
      "AppVersion": 2

    }).subscribe((data: any) => {
      console.log('data', data)
      if (data.success) {
        this.cookieService.set('ApiKey', JSON.stringify(data.extras))
        this.isRouterView = true
      }
      // this.cookieService.set('DeviceID',data.)
    })

  }
}


