import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';

@Injectable()
export class SEOService {
  constructor(private title: Title, private meta: Meta, @Inject(DOCUMENT) private doc:any,
  private sanitizer: DomSanitizer,) { }


  updateTitle(title: string) {
    this.title.setTitle(title);
  }
  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }
  createLinkForCanonicalURL(canonical:any) {
    
    let link: HTMLLinkElement = this.doc.createElement('link' );
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', this.doc.URL);
  }
  getSafeHTML(value: {}) {
    // If value convert to JSON and escape / to prevent script tag in JSON
    const json = value
      ? JSON.stringify(value, null, 2).replace(/\//g, '\\/')
      : '';
    const html = `${json}`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
