import { Injectable, Inject, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
function _window(): any {
  // return the global native browser window object
  return window;
}
@Injectable({
  providedIn: 'root',
})
export class AppService {
  limit = 10;

  splashScreenEventEmiter = new EventEmitter(true);
  Url: string = environment.baseUrl;
  InvoiceUrl: string = environment.Invoice_Url;
  ImageUrl: string = environment.Image_Url;

  showPage = new EventEmitter();
  isWebLoginVisible = new EventEmitter();
  LoginVisible = new EventEmitter();
  logOutEventEmitter = new EventEmitter();

  cartInfoEventEmitter = new EventEmitter();
  PinCodeEventEmitter = new EventEmitter();
  isPrescriptionEditing: boolean = false;
  navigateToUploadPrescription: boolean = false;

  isPrescriptionWebEditing: boolean = false;
  navigateToUploadPrescriptionWeb: boolean = false;

  default_Product = '/assets/images/default_product.png';

  walletChecked: boolean = false;
  constructor(
    private httpService: HttpClient,
    private _cookieService: CookieService,
    @Inject(DOCUMENT) private document: any
  ) {}
  onUploadFile(req: any): Observable<any> {
    return this.httpService.request(req);
  }
  postMethod(posturl: string, body: any): Observable<any> {
    body.ApiKey = '';
    body.USERID = '';
    body.SessionID = '';
    body.warehouseId = '';
    if (this._cookieService.check('ApiKey')) {
      body.ApiKey = JSON.parse(this._cookieService.get('ApiKey')).ApiKey;
    }
    if (this._cookieService.check('userData')) {
      body.USERID = JSON.parse(this._cookieService.get('userData')).USERID;
      body.SessionID = JSON.parse(
        this._cookieService.get('userData')
      ).SessionID;
    }
    if (localStorage.getItem('warehouseId')) {
      body.warehouseId = localStorage.getItem('warehouseId');
    }
    return this.httpService.post(this.Url + posturl, body);
  }
  get nativeWindow(): any {
    return _window();
  }
}
