// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: 'developing',
  // baseUrl: `https://xapi.medbuzz.in/app/`,
  // Image_Url: `https://xapi.medbuzz.in/upload/`,
  // Invoice_Url: 'https://i.medbuzz.in/testinvoice/',
  // Prescription_Invoice: 'https://p.medbuzz.in/testPrescription/',
  baseUrl: `https://v2api.medbuzz.in/app/`,
  Image_Url: `https://v2api.medbuzz.in/upload/`,
  Invoice_Url: 'https://v2i.medbuzz.in/invoice/',
  Prescription_Invoice: 'https://p.medbuzz.in/Prescription/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
